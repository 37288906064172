import * as React from 'react'

import type {
  FindSettingsSentimentAnalysisQuery,
  FindSettingsSentimentAnalysisQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import SentimentAnalysisMemberManagement from '../SentimentAnalysisMemberManagement/SentimentAnalysisMemberManagement'

export const beforeQuery = (props) => {
  return {
    variables: props,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  }
}

export const QUERY = gql`
  query FindSettingsSentimentAnalysisQuery {
    memberships: membershipsByClient(activeOnly: true) {
      id
      isSentimentNotificationDefaultRecipient
      sentimentNotificationRecipients {
        sentimentRecipientMembershipId
      }
      user {
        name
        email
        avatarUrl
        position
      }
      membershipGroups {
        membershipGroup {
          id
          name
        }
      }
      sentimentAnalysisPermission {
        id
        disabled
        invited
      }
    }
  }
`

export const Loading = () => (
  <div className="flex min-h-[calc(100vh-14rem)] flex-col items-center justify-center">
    <LoadingSpinner />
  </div>
)

export const Empty = () => (
  <SentimentAnalysisMemberManagement memberships={[]} />
)

export const Failure = ({
  error,
}: CellFailureProps<FindSettingsSentimentAnalysisQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)
export type Membership = FindSettingsSentimentAnalysisQuery['memberships'][0]

export const Success = ({
  memberships,
}: CellSuccessProps<
  FindSettingsSentimentAnalysisQuery,
  FindSettingsSentimentAnalysisQueryVariables
>) => {
  return <SentimentAnalysisMemberManagement memberships={memberships} />
}
