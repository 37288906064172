import { FC, useState } from 'react'

import { Box } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import dayjs from 'dayjs'
import type {
  FindFlaggedSentimentEmailsByMemberQuery,
  LandlordFlaggedEmailWithoutResolversFields,
} from 'types/graphql'

import Accordion from 'src/components/Library/Accordion/Accordion'
import SentimentAnalysisEmailHeader from 'src/components/SentimentAnalysis/SentimentAnalysisList/SentimentAnalysisEmailHeader'
import FlaggedEmailReviewModal from 'src/components/Settings/SentimentAnalysis/FlaggedEmailReviewModal'
import { MemberFlaggedEmailsHistory } from 'src/components/Settings/SentimentAnalysis/FlaggedSentimentEmailsHistoryByMemberCell/FlaggedSentimentEmailsHistoryByMemberCell'

import type { MemberFlaggedEmails } from './FlaggedSentimentEmailsByMemberCell'

type FlaggedEmailWithHistory = MemberFlaggedEmailsHistory['emails'][0]
type FlaggedEmail = MemberFlaggedEmails['emails'][0]

interface FlaggedEmailListProps {
  flaggedEmailsList: FindFlaggedSentimentEmailsByMemberQuery['memberFlaggedEmails']
  memberships: FindFlaggedSentimentEmailsByMemberQuery['memberships']
  archivedView?: boolean
}

const FlaggedEmailsList: FC<FlaggedEmailListProps> = ({
  flaggedEmailsList,
  memberships,
  archivedView = false,
}) => {
  const [expandedContactIds, setExpandedContactIds] = useState<number[]>([])
  const [selectedEmail, setSelectedEmail] = useState<FlaggedEmail>(null)

  const handleExpand = (id: number) => {
    if (!expandedContactIds.includes(id)) {
      setExpandedContactIds([...expandedContactIds, id])
    }
  }

  const handleCollapse = (id: number) => {
    setExpandedContactIds([
      ...expandedContactIds.filter((savedId) => savedId !== id),
    ])
  }

  const AccordionHeader = ({ membership }) => {
    return (
      <div className="row flex w-full flex-wrap items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          <Avatar className="h-8 w-8" src={membership?.user?.avatarUrl} />
          <span className="mr-4 text-sm uppercase">
            {membership?.user?.name}
          </span>
        </div>
      </div>
    )
  }

  return (
    <>
      {selectedEmail && (
        <FlaggedEmailReviewModal
          email={selectedEmail}
          onClose={() => {
            setSelectedEmail(null)
          }}
          isOpen={selectedEmail !== null}
          createdByMember={memberships?.find(
            (member) => member.user.id === selectedEmail?.createdBy,
          )}
          reviewedByMember={memberships?.find(
            (member) => member.id === selectedEmail?.reviewedByMembershipId,
          )}
          archivedView={archivedView}
          memberGroupMember={memberships.find(
            (membership) =>
              membership.id ===
              flaggedEmailsList.find(
                (memberGroup) =>
                  memberGroup.membershipId === selectedEmail?.membershipId,
              )?.membershipId,
          )}
        />
      )}

      <div className={'mt-4 flex flex-col gap-2'}>
        {flaggedEmailsList.map(
          (memberGroup: MemberFlaggedEmails | MemberFlaggedEmailsHistory) => (
            <Accordion
              key={memberGroup.membershipId}
              expanded={expandedContactIds.includes(memberGroup.membershipId)}
              title={
                <AccordionHeader
                  membership={memberships.find(
                    (member) => member.id === memberGroup.membershipId,
                  )}
                />
              }
              removeDetailsPadding={true}
              onExpand={() => {
                handleExpand(memberGroup.membershipId)
              }}
              onCollapse={() => {
                handleCollapse(memberGroup.membershipId)
              }}
            >
              {memberGroup.emails.map(
                (email: FlaggedEmail | FlaggedEmailWithHistory) => (
                  <div className={'border-b border-gray-100'} key={email.id}>
                    <Box
                      className={`!rounded-none p-1.5 transition-colors hover:cursor-pointer hover:border-indigo-400 hover:bg-indigo-100 ${
                        selectedEmail?.id === email.id
                          ? 'bg-indigo-100'
                          : 'bg-white'
                      }`}
                      onClick={() => setSelectedEmail(email)}
                    >
                      <SentimentAnalysisEmailHeader
                        isAdminView
                        title={dayjs(email.landLordEmail.receivedAt).format(
                          'DD/MM/YYYY h:mma',
                        )}
                        csiRating={email.landLordEmail.sentimentScores['nps']}
                        id={email.landLordEmail.id}
                        email={email.landLordEmail}
                        landlordName={email.landLordEmail.landlordDetails.name}
                        flaggedEmail={
                          email as unknown as LandlordFlaggedEmailWithoutResolversFields
                        }
                        originalSentiment={(() => {
                          const emailWithHistory =
                            email as FlaggedEmailWithHistory
                          return (
                            emailWithHistory?.originalSentiment?.['nps'] ??
                            undefined
                          )
                        })()}
                        memberships={memberships}
                      />
                    </Box>
                  </div>
                ),
              )}
            </Accordion>
          ),
        )}
      </div>
    </>
  )
}

export default FlaggedEmailsList
